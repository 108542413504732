define('client/components/submission-session', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    session: null,
    form: null,

    startTime: computed('session.startTime', function startTime() {
      var start = this.get('session.startTime');
      return start ? start.format('h:mm A') : '*';
    }),

    endTime: computed('session.endTime', function endTime() {
      var end = this.get('session.endTime');
      return end ? end.format('h:mm A') : '*';
    })
  });
});