define('client/components/student-list', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    students: null,

    studentSort: ['lastName'],
    sortedStudents: computed.sort('students', 'studentSort'),

    activeStudents: computed.filter('sortedStudents.@each.isActive', function (student) {
      return student.get('isActive');
    }),

    inactiveStudents: computed.filter('sortedStudents.@each.isActive', function (student) {
      return !student.get('isActive');
    }),

    studentsWithNotice: computed.filter('activeStudents.@each.messages', function (student) {
      return student.get('messages.length') > 0;
    }),

    filteredStudents: computed.filter('activeStudents.@each.messages', function (student) {
      return student.get('messages.length') <= 0;
    })
  });
});