define('client/routes/logout', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    session: _ember['default'].inject.service('session'),

    redirect: function redirect(model, transition) {
      var session = this.get('session');
      if (session.get('isAuthenticated')) {
        this.get('session').invalidate();
      }
      this.replaceWith('login');
    }
  });
});