define('client/components/form-session', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['form-session'],

    locked: false,
    reinitOnChange: null,
    lastReinitOnChange: null,

    count: 1,
    peopleMax: 50,

    isIndividualActive: true,
    isGroupActive: computed.not('isIndividualActive'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get('reinitOnChange') !== this.get('lastReinitOnChange')) {
        var count = this.get('count');
        if (count > 1) {
          this.set('isIndividualActive', false);
        } else {
          this.set('isIndividualActive', true);
        }
        this.set('lastReinitOnChange', this.get('reinitOnChange'));
      }
    },

    actions: {

      selectIndividual: function selectIndividual() {
        if (!this.get('isIndividualActive')) {
          this.set('count', 1);
          this.set('isIndividualActive', true);
          this.sendAction('setCount', this.get('count'));
        }
      },

      selectGroup: function selectGroup() {
        if (!this.get('isGroupActive')) {
          this.set('count', 2);
          this.set('isIndividualActive', false);
          this.sendAction('setCount', this.get('count'));
        }
      },

      decrementPeopleCount: function decrementPeopleCount() {
        var initial = parseInt(this.get('count'));
        if (initial - 1 >= 2) {
          this.set('count', initial - 1);
          this.sendAction('setCount', this.get('count'));
        }
      },

      incrementPeopleCount: function incrementPeopleCount() {
        var initial = parseInt(this.get('count'));
        var max = this.get('peopleMax');
        if (initial + 1 <= max) {
          this.set('count', initial + 1);
          this.sendAction('setCount', this.get('count'));
        }
      }
    }

  });
});