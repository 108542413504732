define('client/helpers/in-array', ['exports', 'ember', 'lodash'], function (exports, _ember, _lodash) {
  exports.inArray = inArray;

  function inArray(params) {
    if (params[0] && params[1]) {
      return Boolean(~_lodash['default'].indexOf(params[1], params[0]));
    }

    return false;
  }

  exports['default'] = _ember['default'].Helper.helper(inArray);
});