define('client/models/special-transportation-encounter', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    date: attr('moment-day'),
    toSchool: attr('boolean'),
    fromSchool: attr('boolean'),
    notes: attr('string'),

    form: belongsTo('form', { polymorphic: true }),

    objectives: hasMany('objective'),

    isComplete: computed('toSchool', 'fromSchool', 'hasDirtyAttributes', function isComplete() {
      return (this.get('toSchool') || this.get('fromSchool')) && this.get('hasDirtyAttributes');
    })
  });
});