define('client/components/change-password', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    resetPassword: _ember['default'].inject.service(),
    error: null,
    success: false,

    currentPassword: null,
    newPassword: null,
    newPasswordConfirmation: null,

    actions: {
      changePassword: function changePassword() {
        var _this = this;

        this.set('error', null);
        this.set('success', false);

        var pws = this.getProperties(['newPasswordConfirmation', 'currentPassword', 'newPassword']);

        if (pws.newPassword !== pws.newPasswordConfirmation) {
          this.set('error', 'Passwords don\'t match');
          return;
        }

        if (pws.newPassword.length < 6) {
          this.set('error', 'Password must be at least 6 characters');
          return;
        }

        this.get('resetPassword').changePassword(pws).then(function () {
          return _this.set('success', true);
        })['catch'](function (e) {
          return _this.set('error', e);
        });
      }
    }
  });
});