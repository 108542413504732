define('client/models/personal-care-encounter', ['exports', 'ember-data', 'ember', 'lodash'], function (exports, _emberData, _ember, _lodash) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    date: attr('moment-day'),
    otherStudentsCount: attr('number', { defaultValue: 1 }),
    minutes: attr('number', { defaultValue: 0 }),
    notes: attr('string'),

    form: belongsTo('form', { polymorphic: true }),
    timeStudyType: belongsTo('time-study-type'),

    user: belongsTo('user'),

    createdAt: attr('moment'),
    updatedAt: attr('moment'),

    isComplete: computed('otherStudentsCount', 'minutes', function isComplete() {
      return +this.get('otherStudentsCount') > 0 && +this.get('minutes') > 0;
    }),

    canSave: computed('isComplete', 'isSaving', function canSubmit() {
      return this.get('isComplete') && !this.get('isSaving');
    }),

    currentUser: _ember['default'].inject.service(),
    canDelete: computed(function () {
      return this.get('currentUser.user.id') === this.get('user.id');
    })

  });
});