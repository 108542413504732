define('client/routes/frame/students/index', ['exports', 'ember', 'client/mixins/titled-page', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _clientMixinsTitledPage, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _clientMixinsTitledPage['default'], {
    pageTitle: 'Students',
    showBack: false,

    model: function model() {
      return this.get('store').findAll('student', { backgroundReload: true });
    },

    setupController: function setupController(controller, model) {
      controller.set('students', model);
    }
  });
});