define('client/services/reset-password', ['exports', 'ember', 'client/config/environment'], function (exports, _ember, _clientConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    ajax: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    resetPassword: function resetPassword(email) {
      return this.get('ajax').request(_clientConfigEnvironment['default'].apiRoot + '/api/password/email', {
        method: 'POST',
        data: JSON.stringify({ email: email })
      })['catch'](throwErrorMessage);
    },

    changePassword: function changePassword(_ref) {
      var currentPassword = _ref.currentPassword;
      var newPassword = _ref.newPassword;

      var email = this.get('currentUser.user.email');
      return this.get('ajax').request(_clientConfigEnvironment['default'].apiRoot + '/api/password/reset', {
        method: 'POST',
        data: JSON.stringify({
          email: email,
          current_password: currentPassword,
          new_password: newPassword,
          new_password_confirmation: newPassword
        })
      })['catch'](throwErrorMessage);
    }
  });

  function throwErrorMessage(response) {
    debugger;
    throw _ember['default'].get(response, 'errors.firstObject.detail') || 'An error occurred.';
  }
});