define('client/components/reset-password', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    resetPassword: _ember['default'].inject.service(),
    email: null,
    error: null,
    success: false,

    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        this.set('error', null);
        this.set('success', false);

        this.get('resetPassword').resetPassword(this.get('email')).then(function () {
          return _this.set('success', true);
        })['catch'](function (e) {
          return _this.set('error', e);
        });
      }
    }
  });
});