define('client/components/login-form', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend({
    classNames: ['login-form'],

    session: inject.service('session'),

    // Properties
    username: null,
    password: null,
    error: null,

    actions: {
      login: function login() {
        var _this = this;

        var _getProperties = this.getProperties('username', 'password');

        var username = _getProperties.username;
        var password = _getProperties.password;

        if (username && password) {
          this.get('session').authenticate('authenticator:oauth2', username, password)['catch'](function (response) {
            if (response.errors && response.errors[0] && response.errors[0].title) {
              _this.set('error', response.errors[0].title);
            }
          });
        }
      }
    }
  });
});