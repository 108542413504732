define('client/models/form', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({
    endDate: attr('moment-day'),
    startDate: attr('moment-day'),
    suggestedMinutes: attr('number', { defaultValue: 0 }),

    iep: belongsTo('iep'),
    student: belongsTo('student'),
    submissions: hasMany('submission'),

    absences: hasMany('absence'),
    formType: belongsTo('form-type'),
    restrictedDates: hasMany('restricted-date')
  });
});