define("client/components/form-calendar", ["exports", "ember", "moment", "lodash"], function (exports, _ember, _moment, _lodash) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend({
    tagName: 'section',
    classNames: ['form-calendar'],

    referenceDate: computed('minDate', 'maxDate', 'selectedDate', function () {
      var selectedDate = this.get('selectedDate');
      if (selectedDate) {
        return selectedDate;
      }
      var today = (0, _moment["default"])().startOf('day');
      var maxDate = this.get('maxDate');

      if (maxDate && today.isAfter(maxDate, 'month')) {
        this.set('referenceDate', maxDate.clone().startOf('day'));
      } else {
        this.set('referenceDate', (0, _moment["default"])().startOf('day'));
      }
      return this.get('referenceDate');
    }),
    selectedDate: null, // the currently selected date
    minDate: null, // moment with the inclusive minimum date
    maxDate: null, // moment with the inclusive maximum date
    restrictedDates: null, // array of moment objects to restrict
    highlightedDates: null, // array of moment objects to highlight
    absentHighlightedDates: null, // array of moment objects to highlight
    view: 'week', // the view "week" or "month"

    viewStartOfWeek: computed('referenceDate', function () {
      return this.get('referenceDate').clone().startOf('week');
    }),

    viewStartOfMonth: computed('referenceDate', function () {
      return this.get('referenceDate').clone().startOf('month');
    }),

    viewStartDate: computed('view', 'viewStartOfWeek', 'viewStartOfMonth', function firstOfWeek() {
      if (this.get('view') === 'week') {
        return _moment["default"].max(this.get('viewStartOfWeek'), this.get('viewStartOfMonth'));
      } else {
        return this.get('viewStartOfMonth');
      }
    }),

    viewDays: computed('view', 'selectedDate', 'viewStartDate', 'minDate', 'maxDate', '_restrictedDates', '_highlightedDates', '_absentHighlightedDates', function viewDays() {
      var view = this.get('view');
      var selectedDate = this.get('selectedDate') && formatForDayComparison(this.get('selectedDate'));
      var viewStartDate = this.get('viewStartDate');
      var minDate = this.get('minDate');
      var maxDate = this.get('maxDate');
      var restrictedDates = this.get('_restrictedDates') || [];
      var highlightedDates = this.get('_highlightedDates') || [];
      var absentHighlightedDates = this.get('_absentHighlightedDates') || [];

      // start the calendar on the right day of week
      var days = _lodash["default"].times(viewStartDate.day(), _lodash["default"].constant(null));

      // calculate the number of days to display
      var numDays = viewStartDate.daysInMonth();
      if (view === 'week') {
        numDays = 7 - viewStartDate.day();
      }

      // for each number of days, clone the previous day and add one day
      var incDay = viewStartDate.clone();

      var _loop = function (i) {
        var day = incDay.clone();
        var minMaxRestricted = (minDate ? day.isBefore(minDate, 'day') : false) || (maxDate ? day.isAfter(maxDate, 'day') : false);
        var eqCurrentDay = (function () {
          var fday = formatForDayComparison(day);
          return function (d) {
            return d === fday;
          };
        })();

        day.restricted = minMaxRestricted || restrictedDates.any(eqCurrentDay);
        day.highlighted = highlightedDates.any(eqCurrentDay);
        day.absentHighlighted = absentHighlightedDates.any(eqCurrentDay);
        day.selected = !day.restricted && eqCurrentDay(selectedDate);

        days.push(day);
        incDay.add(1, 'day');
      };

      for (var i = numDays; i > 0; i--) {
        _loop(i);
      }

      return days;
    }),

    _restrictedDates: computed('restrictedDates.[]', function () {
      return this.get('restrictedDates').map(formatForDayComparison);
    }),
    _highlightedDates: computed('highlightedDates.[]', function () {
      return this.get('highlightedDates').map(formatForDayComparison);
    }),
    _absentHighlightedDates: computed('absentHighlightedDates.[]', function () {
      return this.get('absentHighlightedDates').map(formatForDayComparison);
    }),

    canNextMonth: computed('referenceDate', 'maxDate', function canNextMonth() {
      var reference = this.get('referenceDate');
      var max = this.get('maxDate');
      return max ? reference.isBefore(max, 'month') : true;
    }),

    canPreviousMonth: computed('referenceDate', 'minDate', function canPreviousMonth() {
      var reference = this.get('referenceDate');
      var min = this.get('minDate');
      return min ? reference.isAfter(min, 'month') : true;
    }),

    actions: {
      switchView: function switchView() {
        if (this.get('view') === 'month') {
          this.set('view', 'week');
        } else {
          this.set('view', 'month');
        }
        this.sendAction('calendarSwitchView', this.get('view'));
      },

      selectDate: function selectDate(date) {
        var notRestricted = !date.restricted;
        var notCurrent = this.get('selectedDate') ? !this.get('selectedDate').isSame(date, 'day') : true;
        if (notRestricted && notCurrent) {
          this.sendAction('calendarSelectDate', date);
          this.sendAction('resetTemporarySessions');
          this.set('selectedDate', date.clone());
        }
      },

      nextMonth: function nextMonth() {
        this.set('referenceDate', this.get('referenceDate').clone().add('1', 'month'));
        this.sendAction('calendarNextMonth', this.get('referenceDate'));
      },

      previousMonth: function previousMonth() {
        this.set('referenceDate', this.get('referenceDate').clone().subtract('1', 'month'));
        this.sendAction('calendarPreviousMonth', this.get('referenceDate'));
      }
    }
  });

  function formatForDayComparison(date) {
    return date.format('YYYY-MM-DD');
  }
});