define('client/mixins/titled-page', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var inject = _ember['default'].inject;
  var on = _ember['default'].on;
  exports['default'] = Mixin.create({
    titleService: inject.service('title'),

    pageTitle: null,
    showBack: null,

    setPageTitle: on('activate', function setPageTitle() {
      var title = this.get('titleService');
      title.setProperties({
        current: this.get('pageTitle'),
        showBack: this.get('showBack')
      });
    })
  });
});