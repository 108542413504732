define('client/components/student-list-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend({
    classNames: ['student-list-item'],

    routing: inject.service('-routing'),

    student: null,

    actions: {
      click: function click() {
        this.get('routing.router').transitionTo('frame.students.student.forms', this.get('student'));
      }
    }
  });
});