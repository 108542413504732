define('client/models/personal-care-form', ['exports', 'ember-data', 'client/models/form'], function (exports, _emberData, _clientModelsForm) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _clientModelsForm['default'].extend({
    title: 'Personal Care',
    slug: 'personal-care',
    timeStudies: hasMany('time-study'),
    encounters: hasMany('personal-care-encounter'),
    datesWithEncounters: attr()
  });
});