define('client/components/session-list-item', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    // Attributes
    minutes: null,
    forceActive: false,
    locked: false,
    sessionNumber: null,
    startTime: null,
    endTime: null,
    selectedDate: null,
    disableStudents: false,
    isTelehealth: false,
    telehealthMode: null,
    telehealthProviderSite: null,
    telehealthStudentSite: null,
    widgetPosition: {
      vertical: 'bottom'
    },

    willDestroyElement: function willDestroyElement() {
      var startTime = this.$('.datepicker-start');
      var endTime = this.$('.datepicker-end');
      if (startTime) {
        startTime.data('DateTimePicker').destroy();
      }
      if (endTime) {
        endTime.data('DateTimePicker').destroy();
      }
    },

    // Properties

    sessionMinutes: computed('startTime', 'endTime', function () {
      var startTime = this.get('startTime');
      var endTime = this.get('endTime');
      var minutesCalculated = startTime && endTime ? (0, _moment['default'])(endTime).diff((0, _moment['default'])(startTime), 'minutes') : 0;
      return minutesCalculated && minutesCalculated > 0 ? minutesCalculated : this.get('minutes') ? this.get('minutes') : '0';
    }),

    actions: {
      removeSession: function removeSession() {
        this.sendAction('removeSession', this);
      }
    }
  });
});