define('client/models/submission', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var computed = _ember['default'].computed;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    startDate: attr('moment-day'),
    endDate: attr('moment-day'),
    signedAt: attr('moment'),
    signature: attr('string'),
    didNotSee: attr('boolean'),
    encounterCount: attr('number'),
    encounterSummary: attr(),

    fields: attr('form-fields'),

    student: belongsTo('student'),
    form: belongsTo('form', { polymorphic: true }),

    isComplete: computed('signature', function isComplete() {
      return this.get('signature') !== null;
    })
  });
});