define('client/adapters/company-info', ['exports', 'client/adapters/application'], function (exports, _clientAdaptersApplication) {
  exports['default'] = _clientAdaptersApplication['default'].extend({
    // ED doesn't work well with singleton resources. To fetch the 'company-info'
    // singleton resource, we override pathForType so that `findAll` doesn't
    // pluralize 'company-info'. Then we can just grab the first record from the
    // resulting array...
    //
    //  let companyInfoPromise = this.get('store').findAll('company-info').then(rs => rs.get('firstObject'));
    //
    // Definitely awkward, but I don't think there's a non-awkward way to use ED for this.
    pathForType: function pathForType() {
      return 'company-info';
    }
  });
});