define('client/models/mental-health-form', ['exports', 'ember-data', 'client/models/form'], function (exports, _emberData, _clientModelsForm) {
  var hasMany = _emberData['default'].hasMany;
  var attr = _emberData['default'].attr;
  exports['default'] = _clientModelsForm['default'].extend({
    title: 'Mental Health',
    slug: 'mental-health',
    suggestedMinutes: attr('number', { defaultValue: 0 }),
    encounters: hasMany('mental-health-encounter'),
    availableGoals: hasMany('goal')
  });
});