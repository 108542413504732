define('client/mixins/form-model-mixin', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var attr = _emberData['default'].attr;
  exports['default'] = _ember['default'].Mixin.create({
    endDate: attr('moment-day'),
    startDate: attr('moment-day'),
    suggestedMinutes: attr('number', { defaultValue: 0 }),

    iep: belongsTo('iep'),
    submissions: hasMany('submission'),

    absences: hasMany('absence'),
    formType: belongsTo('form-type'),
    restrictedDates: hasMany('restricted-date')
  });
});