define('client/components/x-stepper', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    value: 0,

    classNames: ['x-stepper'],

    actions: {
      increment: function increment() {
        this.sendAction('increment');
      },

      decrement: function decrement() {
        this.sendAction('decrement');
      }
    }
  });
});