define('client/router', ['exports', 'ember', 'client/config/environment'], function (exports, _ember, _clientConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _clientConfigEnvironment['default'].locationType,
    rootURL: _clientConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('frame', { path: '/' }, function () {
      this.route('students', function () {
        this.route('student', { path: '/:studentId' }, function () {
          this.route('forms', function () {
            var _this = this;

            ['mental-health', 'nursing', 'occupational-therapy', 'personal-care', 'physical-therapy', 'special-transportation', 'speech-therapy'].forEach(function (routeName) {
              return _this.route(routeName, { path: routeName + '/:formId' });
            });
          });
          this.route('submissions', function () {
            this.route('submission', { path: '/:submissionId' });
          });
        });
      });
    });
    this.route('login');
    this.route('reset-password');
    this.route('logout');
    this.route('test');
  });

  exports['default'] = Router;
});