define('client/components/form-special-transportation', ['exports', 'ember', 'client/mixins/form-mixin'], function (exports, _ember, _clientMixinsFormMixin) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_clientMixinsFormMixin['default'], {
    classNames: ['form', 'form-special-transportation'],
    encounterType: 'specialTransportationEncounter',

    actions: {
      toggleToSchool: function toggleToSchool() {
        this.toggleProperty('currentEncounter.toSchool');
      },

      toggleFromSchool: function toggleFromSchool() {
        this.toggleProperty('currentEncounter.fromSchool');
      },

      recordedEncounter: function recordedEncounter(encounter) {
        // nothing to do
      },

      deletedEncounter: function deletedEncounter(encounter) {
        // nothing to do
      }
    }
  });
});