define("client/components/form-telehealth", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend({

    modes: ["Zoom", "Google Meet", "Skype", "Microsoft Teams", "Doxy.me", "SeeSaw", "Bluejeans"],

    providerSites: ["Provider's Office", "Provider's Home", "At School"],

    studentSites: ["Student's Home", "At School"],

    willDestroyElement: function willDestroyElement() {},

    // Properties

    sessionMinutes: computed('startTime', 'endTime', function () {}),

    actions: {
      selectTelehealth: function selectTelehealth() {
        var newVal = !this.get('isTelehealth');
        this.set('isTelehealth', newVal);
        if (newVal === true) {
          this.set('telehealthMode', this.get('telehealthMode') ? this.get('telehealthMode') : this.get('modes')[0]);
          this.set('telehealthProviderSite', this.get('telehealthProviderSite') ? this.get('telehealthProviderSite') : this.get('providerSites')[0]);
          this.set('telehealthStudentSite', this.get('telehealthStudentSite') ? this.get('telehealthStudentSite') : this.get('studentSites')[0]);
        } else {
          this.set('telehealthMode', null);
          this.set('telehealthProviderSite', null);
          this.set('telehealthStudentSite', null);
        }
      },

      selectMode: function selectMode(e) {
        this.set('telehealthMode', e.target.value);
      },

      selectProviderSite: function selectProviderSite(e) {
        this.set('telehealthProviderSite', e.target.value);
      },

      selectStudentSite: function selectStudentSite(e) {
        this.set('telehealthStudentSite', e.target.value);
      }
    }
  });
});