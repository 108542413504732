define('client/instance-initializers/browser/ember-href-to', ['exports', 'ember'], function (exports, _ember) {
  exports.canHandle = canHandle;

  function _getNormalisedRootUrl(router) {
    var rootURL = router.rootURL;
    if (rootURL.charAt(rootURL.length - 1) !== '/') {
      rootURL = rootURL + '/';
    }
    return rootURL;
  }

  function _lookupRouter(applicationInstance) {
    var container = 'lookup' in applicationInstance ? applicationInstance : applicationInstance.container;
    return container.lookup('router:main');
  }

  function canHandle(e) {
    var $target = _ember['default'].$(e.currentTarget);
    var handleClick = e.which === 1 && !e.ctrlKey && !e.metaKey;

    return handleClick && !$target.hasClass('ember-view') && _ember['default'].isNone($target.attr('data-href-to-ignore')) && _ember['default'].isNone($target.attr('data-ember-action')) && $target.attr('download') === undefined;
  }

  exports['default'] = {
    name: 'ember-href-to',
    initialize: function initialize(applicationInstance) {
      var router = _lookupRouter(applicationInstance);
      var rootURL = _getNormalisedRootUrl(router);
      var $body = _ember['default'].$(document.body);

      $body.off('click.href-to', 'a');
      $body.on('click.href-to', 'a', function (e) {
        if (canHandle(e)) {
          var $target = _ember['default'].$(e.currentTarget);
          var url = $target.attr('href');

          if (url && url.indexOf(rootURL) === 0) {
            url = url.substr(rootURL.length - 1);

            if (router.router.recognizer.recognize(url)) {
              router.handleURL(url);
              router.router.updateURL(url);
              e.preventDefault();
            }
          }
        }
        return true;
      });
    }
  };
});