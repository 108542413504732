define('client/models/nursing-encounter', ['exports', 'ember-data', 'ember', 'lodash'], function (exports, _emberData, _ember, _lodash) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    date: attr('moment-day'),
    minutes: attr('number', { defaultValue: 0 }),
    startTime: attr('moment-time', { defaultValue: null }),
    endTime: attr('moment-time', { defaultValue: null }),
    notes: attr('string'),

    form: belongsTo('form', { polymorphic: true }),
    objectives: hasMany('objective'),

    isComplete: computed('objectives', 'otherStudentsCount', 'startTime', 'endTime', function isComplete() {
      var startTime = this.get('startTime');
      var endTime = this.get('endTime');
      var metrics = [Boolean(+this.get('startTime') != null), Boolean(+this.get('endTime') != null), Boolean(startTime && endTime ? moment(this.get('endTime').format('h:mma'), 'h:mma').isAfter(moment(this.get('startTime').format('h:mma'), 'h:mma')) : false)];
      return metrics.length === _lodash['default'].compact(metrics).length;
    })
  });
});