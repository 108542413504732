define("client/transforms/moment", ["exports", "ember-data", "moment"], function (exports, _emberData, _moment) {
  exports["default"] = _emberData["default"].Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized ? (0, _moment["default"])(serialized, _moment["default"].ISO_8601) : null;
    },

    serialize: function serialize(deserialized) {
      return deserialized ? deserialized.toISOString() : null;
    }
  });
});