define('client/routes/login', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
  var Route = _ember['default'].Route;
  var $ = _ember['default'].$;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {

    session: _ember['default'].inject.service('session'),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var session = this.get('session');
      var resume = transition.queryParams.resume;
      if (resume) {
        session.authenticate('authenticator:oauth2', $.parseJSON(resume)).then(function () {
          _this.replaceWith('frame.students');
        });
      } else {
        return this._super.apply(this, arguments);
      }
    }

  });
});