define('client/routes/frame/students/student/submissions/submission', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'client/mixins/titled-page'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _clientMixinsTitledPage) {
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _clientMixinsTitledPage['default'], {

    pageTitle: 'Submission',
    showBack: true,

    toast: inject.service(),

    model: function model(params) {
      return this.store.findRecord('submission', +params.submissionId, { reload: true });
    },

    setupController: function setupController(controller, model) {
      controller.set('submission', model);
      controller.set('student', this.modelFor('frame.students.student'));
    },

    setPageTitle: null,
    afterModel: function afterModel(model) {
      var title = this.get('titleService');
      title.setProperties({
        current: this.modelFor('frame.students.student').get('fullName'),
        showBack: true
      });
    },

    redirect: function redirect(model, transition) {
      var _this = this;

      var info = function info(msg) {
        return _this.get('toast').info(msg);
      };
      var transitionToForms = function transitionToForms() {
        return _this.transitionTo('frame.students.student.forms.index');
      };

      if (model.get('signedAt')) {
        info('Submission already completed');
        transitionToForms();
      } else if (this.modelFor('frame.students.student').get('id') !== model.get('student.id')) {
        info('Invalid submission');
        transitionToForms();
      }
    }

  });
});