define('client/models/student', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    age: attr('number'),
    birthDate: attr('moment-day'),
    iepStartDate: attr('moment-day'),
    iepEndDate: attr('moment-day'),

    isActive: attr('boolean'),

    messages: attr(),

    school: belongsTo('school'),

    ieps: hasMany('iep'),

    fullName: computed('firstName', 'lastName', function fullName() {
      var _getProperties = this.getProperties('firstName', 'lastName');

      var firstName = _getProperties.firstName;
      var lastName = _getProperties.lastName;

      return firstName + ' ' + lastName;
    })
  });
});