define('client/models/user', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var computed = _ember['default'].computed;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    professionalTitle: attr('string'),

    name: computed('firstName', 'lastName', function name() {
      return this.get('lastName') + ', ' + this.get('firstName');
    })
  });
});