define('client/mixins/therapy-form-mixin', ['exports', 'ember', 'client/mixins/form-mixin', 'lodash', 'moment'], function (exports, _ember, _clientMixinsFormMixin, _lodash, _moment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  var EmberObject = _ember['default'].Object;
  exports['default'] = Mixin.create(_clientMixinsFormMixin['default'], {
    timeMax: 999,
    peopleMax: 50,

    ajax: inject.service(),

    modalOpen: false,
    sessionToDelete: null,
    sessionsToDelete: [],
    encountersLoading: false,

    willDestroy: function willDestroy() {
      this.send('clearEncounter');
      this.resetEncounter();
    },

    resetEncounter: function resetEncounter() {
      this.setProperties({
        sessionToDelete: null,
        sessionsToDelete: [],
        modalOpen: false,
        selectedGoalsTmp: [],
        newSessionsTmp: []
      });
    },

    isSaving: false,
    objectiveSelected: false,

    goals: computed.alias('form.availableGoals'),

    selectedGoalsTmp: [],
    selectedGoalIds: computed('selectedGoalsTmp', 'currentEncounter.@each.objectives', function () {
      return this.get('selectedGoalsTmp') && this.get('currentEncounter.objectives') ? [].concat(_toConsumableArray(this.get('selectedGoalsTmp').mapBy('id')), _toConsumableArray(this.get('currentEncounter.objectives').mapBy('goal').mapBy('id'))).uniq() : [];
    }),
    selectedGoals: computed('selectedGoalIds', function () {
      var selectedIds = this.get('selectedGoalIds');
      return this.get('goals').filter(function (goal) {
        return selectedIds.includes(goal.id);
      });
    }),
    selectedObjectiveIds: computed.mapBy('currentEncounter.objectives', 'id'),

    hasObjectives: computed('objectives', function () {
      return this.get('objectives').length > 0;
    }),

    objectives: computed('selectedGoals', function objectives() {
      var goals = this.get('selectedGoals');
      return goals.reduce(function (previousValue, item) {
        return [].concat(_toConsumableArray(previousValue), _toConsumableArray(item.get('objectives').toArray()));
      }, []);
    }),

    sessionViewModels: computed('currentEncounters.[]', function () {
      var encounters = this.get('currentEncounters');
      return encounters.sortBy('id');
    }),

    newSessionsTmp: [],
    newSessions: computed('currentEncounters.[]', 'newSessionsTmp.[]', function () {
      var newSessions = this.get('newSessionsTmp');
      var currentEncounters = this.get('currentEncounters');

      if ((!currentEncounters || currentEncounters.length === 0) && (!newSessions || newSessions.length === 0)) {
        var e = EmberObject.create({
          startTime: null,
          endTime: null,
          otherStudentsCount: 1
        });
        newSessions.pushObject(e);
      }
      return this.get('newSessionsTmp');
    }),

    canSubmit: computed('currentDayLocked', 'encountersAreComplete', 'encountersWithDirtyAttributes.[]', 'objectiveSelected', 'selectedObjectiveIds.[]', 'sessionsToDelete.[]', 'newSessions.[]', function () {
      return !this.get('currentDayLocked') && this.get('encountersAreComplete') && (this.get('encountersWithDirtyAttributes').length > 0 || this.get('objectiveSelected') && this.get('selectedObjectiveIds').length > 0 || this.get('newSessions').length > 0 && this.get('selectedObjectiveIds').length > 0 || this.get('sessionsToDelete').length > 0) && !this.get('isSaving');
    }),

    canDelete: computed('currentDayLocked', 'isSaving', function () {
      return !this.get('currentDayLocked') && !this.get('isSaving');
    }),

    encountersWithDirtyAttributes: computed.filterBy('currentEncounters', 'hasDirtyAttributes', true),

    encountersWithDirtyObjectiveRelationship: computed.filterBy('currentEncounters', 'hasDirtyObjectiveRelationship', true),

    encountersAreComplete: computed('currentEncounters.@each.isComplete', 'newSessions.@each.startTime', 'newSessions.@each.endTime', function () {
      var encounters = this.get('currentEncounters');
      var newSessions = this.get('newSessions');
      return (encounters && encounters.length > 0 ? encounters.findIndex(function (encounter) {
        return !encounter.get('isComplete');
      }) === -1 : true) && (newSessions && newSessions.length > 0 ? newSessions.findIndex(function (session) {
        return session.get('startTime') === null || session.get('endTime') === null || (session.get('startTime') && session.get('endTime') ? !(0, _moment['default'])(session.get('endTime').format('h:mma'), 'h:mma').isAfter((0, _moment['default'])(session.get('startTime').format('h:mma'), 'h:mma')) : false);
      }) === -1 : encounters && encounters.length > 0);
    }),

    newEncounter: computed('newSessions.[]', function () {
      return this.get('newSessions').length > 0;
    }),

    actions: {
      selectGoal: function selectGoal(goal) {
        var currentIds = this.get('selectedGoalIds');
        if (currentIds.includes(goal.id)) {
          var encounters = this.get('currentEncounters');
          encounters.forEach(function (encounter) {
            var objectives = encounter.get('objectives');
            var g;
            while (g = objectives.findBy('goal.id', goal.id)) {
              objectives.removeObject(g);
            }
          });
          this.set('selectedGoalsTmp', this.get('selectedGoalsTmp').filter(function (tempGoal) {
            return tempGoal !== goal;
          }));
        } else {
          this.set('selectedGoalsTmp', [].concat(_toConsumableArray(this.get('selectedGoalsTmp')), [goal]));
        }
      },

      selectObjective: function selectObjective(objective) {
        var encounters = this.get('currentEncounters') && this.get('currentEncounters').length > 0 ? this.get('currentEncounters') : [this.get('currentEncounter')];

        if (encounters && encounters.length > 0) {
          encounters.forEach(function (encounter) {
            var objectives = encounter.get('objectives');
            if (objectives.findBy('id', objective.get('id'))) {
              objectives.removeObject(objective);
            } else {
              objectives.pushObject(objective);
            }
          });
          this.set('objectiveSelected', true);
        }
      },

      createSession: function createSession() {
        var sessions = this.get('newSessionsTmp');
        var e = EmberObject.create({
          startTime: null,
          endTime: null,
          otherStudentsCount: 1,
          isTelehealth: false,
          telehealthMode: null,
          telehealthProviderSite: null,
          telehealthStudentSite: null

        });
        sessions.pushObject(e);
        this.set('newSessionsTmp', sessions);
      },

      removeSession: function removeSession() {
        var session = this.get('sessionToDelete');
        var sessions = this.get('sessionViewModels');
        var newSessions = this.get('newSessionsTmp');
        var sessionsToDelete = this.get('sessionsToDelete');

        if (sessions.includes(session)) {
          sessionsToDelete.pushObject(session);
          this.set('sessionsToDelete', sessionsToDelete);
          sessions.removeObject(session);
        } else if (newSessions.includes(session)) {
          newSessions.removeObject(session);
        }
        this.set('modalOpen', false);
        this.set('sessionToDelete', null);
      },

      recordEncounters: function recordEncounters() {
        var _this = this;

        var newSessions = this.get('newSessions');
        var oldSessions = this.get('sessionViewModels');
        var deleteSessions = this.get('sessionsToDelete');
        var encounterData = {
          notes: this.get('currentEncounter.notes'),
          form_id: this.get('form.id'),
          date: this.get('selectedDate').format('YYYY-MM-DD'),
          objectives: this.get('selectedObjectiveIds').map(function (objective) {
            return {
              id: objective
            };
          }),
          oldSessions: oldSessions.map(function (session) {
            return {
              id: session.get('id'),
              start_time: session.get('startTime') ? session.get('startTime').format('HH:mm') : null,
              end_time: session.get('endTime') ? session.get('endTime').format('HH:mm') : null,
              other_students_count: session.get('otherStudentsCount'),
              is_telehealth: session.get('isTelehealth') ? session.get('isTelehealth') : false,
              telehealth_mode: session.get('telehealthMode') ? session.get('telehealthMode') : null,
              telehealth_provider_site: session.get('telehealthProviderSite') ? session.get('telehealthProviderSite') : null,
              telehealth_student_site: session.get('telehealthStudentSite') ? session.get('telehealthStudentSite') : null
            };
          }),
          newSessions: newSessions.map(function (session) {
            return {
              start_time: session.get('startTime') ? session.get('startTime').format('HH:mm') : null,
              end_time: session.get('endTime') ? session.get('endTime').format('HH:mm') : null,
              other_students_count: session.get('otherStudentsCount'),
              is_telehealth: session.get('isTelehealth') ? session.get('isTelehealth') : false,
              telehealth_mode: session.get('telehealthMode') ? session.get('telehealthMode') : null,
              telehealth_provider_site: session.get('telehealthProviderSite') ? session.get('telehealthProviderSite') : null,
              telehealth_student_site: session.get('telehealthStudentSite') ? session.get('telehealthStudentSite') : null
            };
          }),
          deleteSessions: deleteSessions.map(function (session) {
            return {
              id: session.get('id')
            };
          })
        };
        this.set('isSaving', true);

        this.get('ajax').post(this.get('apiPath') + '/bulk', {
          data: JSON.stringify({ data: encounterData })
        }).then(function (response) {
          _this.set('currentEncounter', null);
          _this.get('store').pushPayload(response);
          var newIds = response.data.mapBy('id');
          var newEncounters = _this.get('store').peekAll(_this.get('encounterType')).filter(function (e) {
            return newIds.includes(e.get('id'));
          });
          _this.get('form.encounters').pushObjects(newEncounters);
          _this.send('recordedEncounter');
          _this.get('toast').success('Saved encounter');
        }, function (response) {
          var errorMessage = response.message ? response.message : 'Server Error. Please Refresh and try again.';
          _this.get('toast').error('Failed to save encounter(s): ' + errorMessage);
        })['finally'](function () {
          _this.set('isSaving', false);
        });
      },

      deleteEncounter: function deleteEncounter() {
        var _this2 = this;

        var encounters = this.get('currentEncounters');
        var encounterData = {
          form_id: this.get('form.id'),
          date: this.get('selectedDate').format('YYYY-MM-DD')
        };
        this.get('ajax').post(this.get('apiPath') + '/bulk-delete', {
          data: JSON.stringify({ data: encounterData })
        }).then(function () {
          encounters.forEach(function (encounter) {
            _this2.get('store').unloadRecord(encounter);
          });
          _this2.set('currentEncounter', null);
          _this2.resetEncounter();
          _this2.get('toast').success('Deleted encounter');
        }, function (response) {
          var errorMessage = response.message ? response.message : 'Server Error';
          _this2.get('toast').error('Failed to delete encounter(s):: ' + errorMessage);
        });
      },

      recordedEncounter: function recordedEncounter() {
        this.setProperties({
          selectedGoalsTmp: [],
          isSaving: false,
          objectiveSelected: false,
          sessionsToDelete: [],
          newSessionsTmp: []
        });
      },

      deletedEncounter: function deletedEncounter() {
        this.set('selectedGoalsTmp', []);
      },

      resetTemporarySessions: function resetTemporarySessions() {
        this.set('newSessionsTmp', []);
      },

      toggleMenu: function toggleMenu(session) {
        var currentEncounters = this.get('currentEncounters');
        if (currentEncounters && currentEncounters.length > 0) {
          var modalOpen = this.toggleProperty('modalOpen');

          if (!modalOpen) {
            this.set('sessionToDelete', null);
          } else if (session) {
            this.set('sessionToDelete', session);
          }
        } else {
          this.set('sessionToDelete', session);
          this.send('removeSession');
        }
      }
    }

  });
});