define('client/controllers/frame/students/student/forms/_base', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['date'],
    date: undefined,

    selectedDate: _ember['default'].computed('date', function () {
      return (0, _moment['default'])(this.get('date'));
    }),

    encountersLoading: false
  });
});