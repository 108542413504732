define('client/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'client/config/environment'], function (exports, _emberSimpleAuthAuthenticatorsOauth2PasswordGrant, _clientConfigEnvironment) {
  var _Ember = Ember;
  var RSVP = _Ember.RSVP;
  var isEmpty = _Ember.isEmpty;
  var assign = _Ember.assign;
  exports['default'] = _emberSimpleAuthAuthenticatorsOauth2PasswordGrant['default'].extend({
    clientId: _clientConfigEnvironment['default']['ember-simple-auth']['oauth2ClientId'],
    serverTokenEndpoint: _clientConfigEnvironment['default'].apiRoot + '/api/oauth/access-token',
    serverTokenRevocationEndpoint: _clientConfigEnvironment['default'].apiRoot + '/api/oauth/revoke',

    authenticate: function authenticate(identification, password) {
      var scope = arguments.length <= 2 || arguments[2] === undefined ? [] : arguments[2];
      var headers = arguments.length <= 3 || arguments[3] === undefined ? {} : arguments[3];

      // if the first parameter is an object, assume it's a token response for resume from query parameters
      if (Ember.typeOf(identification) === 'object') {
        var response = identification;
        var expiresAt = this._absolutizeExpirationTime(response['expires_in']);
        return this._refreshAccessToken(expiresAt, response['refresh_token']);
      }
      return this._super(identification, password, scope, headers);
    }
  });
});