define("client/components/form-list-item", ["exports", "ember", "moment"], function (exports, _ember, _moment) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var inject = _ember["default"].inject;
  exports["default"] = Component.extend({
    classNames: ['form-list-item'],

    routing: inject.service('-routing'),
    toast: inject.service(),

    form: null,

    title: computed.alias('form.title'),
    slug: computed.alias('form.slug'),

    filteredSubmissions: computed('isShowingAll', 'form.submissions.[]', 'lastFourMonths.[]', function () {
      return this.get('isShowingAll') ? this.get('form.submissions') : this.get('lastFourMonths');
    }),

    lastFourMonths: computed('form.submissions.[]', function () {
      return this.get('form.submissions').slice(0, 4);
    }),

    actions: {
      clickLog: function clickLog(submission) {
        var today = (0, _moment["default"])();
        var submissionStartDate = submission.get('startDate');
        var targetDate = submissionStartDate.isSame(today, 'month') ? today : submissionStartDate;

        this.get('routing.router').transitionTo("frame.students.student.forms." + this.get('slug'), submission.get('form.id'), {
          queryParams: {
            date: targetDate.format('YYYY-MM-DD')
          }
        });
      },

      clickSubmission: function clickSubmission(submission) {
        this.get('routing.router').transitionTo("frame.students.student.submissions.submission", submission.get('id'));
      },

      clickDidNotSee: function clickDidNotSee(submission) {
        var toast = this.get('toast');
        submission.toggleProperty('didNotSee');
        submission.save()["catch"](function (error) {
          submission.rollbackAttributes();
          toast.error('There was an error saving your changes');
        });
      },

      toggleMoreLess: function toggleMoreLess() {
        this.toggleProperty('isShowingAll');
      }
    }

  });
});