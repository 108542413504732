define("client/initializers/env-overrides", ["exports", "client/config/environment"], function (exports, _clientConfigEnvironment) {
  exports.initialize = initialize;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  // Merge configuration set in window.EnvOverrides to ember config
  // Used to set configuration dynamically in the Docker container at runtime

  function initialize() /* application */{
    var envOverrides = window.EnvOverrides;
    if (envOverrides) {
      merge(_clientConfigEnvironment["default"], envOverrides);
    }
  }

  function merge(config, override) {
    for (var property in override) {
      if (override.hasOwnProperty(property)) {
        if (config.hasOwnProperty(property)) {
          if (Array.isArray(override[property]) && Array.isArray(config[property])) {
            var _config$property;

            (_config$property = config[property]).push.apply(_config$property, _toConsumableArray(override[property]));
          } else if (typeof override[property] === "object" && typeof config[property] === "object") {
            merge(config[property], override[property]);
          } else {
            config[property] = override[property];
          }
        } else {
          config[property] = override[property];
        }
      }
    }
  }

  exports["default"] = {
    name: 'env-overrides',
    initialize: initialize
  };
});