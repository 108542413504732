define('client/components/submission-personal-care-encounters', ['exports', 'ember', 'lodash'], function (exports, _ember, _lodash) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    encounters: null,
    timeStudies: null,

    pcaTable: null, // initialized in didRender,
    resizeHandler: null, // initialized in didRender,

    didRender: function didRender() {
      if (this.get('aggregateEncounters') !== null && this.get('filteredTimeStudies') !== null && !this.get('pcaTable')) {
        var columns = this.get('filteredTimeStudies').map(function (study) {
          return {
            field: study.name,
            title: study.name,
            'class': 'table-text-ellipsis hide-contents'
          };
        });
        var data = this.get('aggregateEncounters');
        var currentHeight = $(window).height();
        var height = currentHeight > 1050 ? 1000 : currentHeight >= 575 ? 500 : 340;

        columns.unshift({
          field: 'date',
          title: '---',
          'class': 'table-text-ellipsis',
          formatter: function formatter(value, row, index, field) {
            return row.absent ? '<span>' + value + '</span>' : value;
          }
        });

        var table = this.$('.pca-table').bootstrapTable({
          columns: columns,
          data: data,
          fixedColumns: true,
          fixedNumber: 1,
          height: height,
          rowStyle: function rowStyle(row) {
            return {
              classes: row.restricted ? row.absent ? 'absent-circle disabled' : 'disabled' : ''
            };
          }
        });
        this.set('pcaTable', table);

        this.set('resizeHandler', _ember['default'].run.bind(this, this.handleResize));

        $(window).on('resize', _lodash['default'].debounce(this.get('resizeHandler'), 100));
      }
    },

    willDestroyElement: function willDestroyElement() {
      var pcaTable = this.get('pcaTable');
      if (pcaTable) {
        pcaTable.bootstrapTable('destroy');
      }
    },

    didDestroyElement: function didDestroyElement() {
      $(window).unbind('resize', this.get('resizeHandler'));
      this.set('resizeHandler', null);
    },

    handleResize: function handleResize() {
      var table = this.get('pcaTable');
      var currentHeight = $(window).height();
      var height = currentHeight > 1050 ? 1000 : currentHeight >= 575 ? 500 : 340;

      if (table) {
        table.bootstrapTable('resetView', { height: height });
      }
    },

    filteredTimeStudies: computed('timeStudies.@each.value', function filteredTimeStudies() {
      var studies = this.get('timeStudies');
      return studies.map(function (study) {
        var type = study.get('timeStudyType');
        return {
          id: type.get('id'),
          name: type.get('name')
        };
      }).sortBy('name');
    }),

    /*
    *
    * This flattens an organized array of grouped logged encounter counts by the relevent dates in the submission month
    * and then the Personal Care Study Types for the current IEP
    * Sorted by Date and studyType name respectively
    * @return array
    */
    aggregateEncounters: computed('encounters.@each.value', 'filteredTimeStudies.[]', function aggregateEncounters() {
      var timeStudies = this.get('filteredTimeStudies');
      if (this.get('encounters') && timeStudies) {
        var encounterMap = this.get('encounters').map(function (encounter) {
          var totals = encounter.totals;
          var flattenedStudies = timeStudies.map(function (study) {
            var studyID = study.id;
            var count = '';
            if (totals) {
              var countIndex = _lodash['default'].findIndex(totals, function (element) {
                return element.time_study_type_id.toString() === studyID;
              });
              count = countIndex !== -1 ? encounter.totals[countIndex].count : '';
            }

            return _defineProperty({}, study.name, encounter.restricted ? '' : count);
          }).reduce(function (accum, study) {
            return _lodash['default'].merge(accum, study);
          }, {});

          return _lodash['default'].merge({
            date: moment(encounter.date).format('M/D'),
            restricted: encounter.restricted,
            absent: encounter.absent
          }, flattenedStudies);
        });
        return encounterMap;
      }
    })
  });
});