define('client/components/submission-encounter', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    encounter: null,
    form: null,

    absenceType: computed('encounter', function () {
      return this.get('encounter.constructor.modelName') === 'absence';
    }),

    encounterSize: computed('encounter.otherStudentsCount', function encounterSize() {
      var groupCount = this.get('encounter.otherStudentsCount');

      if (!groupCount) return null;
      return groupCount > 1 ? 'G' + groupCount : 'Individ.';
    })
  });
});