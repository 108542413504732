define('client/components/nav-bar', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend({
    routing: inject.service('-routing'),
    titleService: inject.service('title'),
    store: inject.service(),

    title: computed.alias('titleService.current'),
    showBack: computed.alias('titleService.showBack'),

    menuOpen: false,

    companyInfo: null,

    showChangePassword: false,
    showContactUs: false,

    resetMenu: function resetMenu() {
      this.setProperties({
        showChangePassword: false,
        showContactUs: false
      });
    },

    actions: {
      goBack: function goBack() {
        history.back();
      },

      toggleMenu: function toggleMenu() {
        var menuOpen = this.toggleProperty('menuOpen');

        // Reset menu state when closed
        if (!menuOpen) {
          this.resetMenu();
        }
      },

      toggleChangePassword: function toggleChangePassword() {
        this.toggleProperty('showChangePassword');
      },

      toggleContactUs: function toggleContactUs() {
        var _this = this;

        this.toggleProperty('showContactUs');
        this.get('store').findAll('company-info').then(function (rs) {
          _this.set('companyInfo', rs.get('firstObject'));
        });
      },

      logout: function logout() {
        this.get('routing.router').transitionTo('logout');
      }
    }
  });
});