define('client/components/submission-goal-list', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    goals: null,
    sessions: null,

    sessionGoals: computed('goals.[]', 'sessions.@each.objectives.[]', function goalObjectives() {
      var filteredEncounters = this.get('sessions');
      var goals = this.get('goals').map(function (goal) {
        return goal.get('id');
      });
      var filteredGoalIds = filteredEncounters.reduce(function (objectives, encounter) {
        return objectives.concat(encounter.get('objectives').toArray());
      }, []).filter(function (objective) {
        return goals.includes(objective.get('goal.id'));
      }).uniq('id').map(function (objective) {
        return objective.get('goal.id');
      });
      return this.get('goals').filter(function (goal) {
        return filteredGoalIds.includes(goal.get('id'));
      });
    })
  });
});