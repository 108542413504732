define('client/mixins/form-mixin', ['exports', 'ember', 'moment', 'lodash'], function (exports, _ember, _moment, _lodash) {
  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = Mixin.create({
    store: inject.service(),
    toast: inject.service(),

    today: (0, _moment['default'])(),
    selectedDate: null,

    selectedDateOrDefault: computed('selectedDate', 'calendarMinDate', 'calendarMaxDate', 'calendarRestrictedDates.[]', function selectedDateOrDefault() {
      var _this = this;

      // const selectedDate = this.get('selectedDate') ? this.get('selectedDate') : moment();
      // const inMinMax = selectedDate.isSameOrAfter(this.get('calendarMinDate'), 'day')
      //   && selectedDate.isSameOrBefore(this.get('calendarMaxDate'), 'day');
      // const notRestricted = !this.get('calendarRestrictedDates').any((restricted) => {
      //   return selectedDate.isSame(restricted, 'day');
      // });
      // if (inMinMax && notRestricted) {
      //   this.set('selectedDate', selectedDate);
      // } else {
      //   const selectedMonth = moment(selectedDate).startOf('month');
      //   const months =
      //   const firstUnrestrictedDay = _
      //
      //   this.set('selectedDate', this.get('calendarMinDate'));
      //   const date = this.get('calendarMinDate');
      //   this.sendAction('selectDate', date);
      // }
      if (!this.get('selectedDate')) {
        (function () {
          var today = (0, _moment['default'])();
          var inMinMax = today.isSameOrAfter(_this.get('calendarMinDate'), 'day') && today.isSameOrBefore(_this.get('calendarMaxDate'), 'day');
          var notRestricted = !_this.get('calendarRestrictedDates').any(function (restricted) {
            return today.isSame(restricted, 'day');
          });
          if (inMinMax && notRestricted) {
            _this.set('selectedDate', today);
          }
        })();
      }
      return this.get('selectedDate');
    }),

    form: null, // to be defined in implementation
    encounterType: 'personalCareEncounter', // to be defined in implementation

    student: computed.alias('form.student'),

    formType: computed.alias('form.formType'),
    encounters: computed.alias('form.encounters'),
    restrictedDates: computed.alias('form.restrictedDates'),
    absences: computed.alias('form.absences'),

    submissions: computed.alias('form.submissions'),
    currentSubmission: computed('submissions.@each', 'selectedDate', function currentSubmission() {
      var selected = this.get('selectedDate');
      return this.get('submissions').find(function (submission) {
        return selected.isSameOrAfter(submission.get('startDate'), 'day') && selected.isSameOrBefore(submission.get('endDate'), 'day');
      });
    }),
    currentDayLocked: computed.bool('currentSubmission.signedAt'),

    calendarMinDate: computed.alias('form.startDate'),
    calendarMaxDate: computed('form.endDate', 'today', function calendarMaxDate() {
      return _moment['default'].min(this.get('form.endDate'), this.get('today'));
    }),
    calendarRestrictedDates: computed.mapBy('restrictedDates.[]', 'date'),
    calendarHighlightedDates: computed.mapBy('encounters.[]', 'date'),
    calendarAbsentHighlightedDates: computed.mapBy('absences.[]', 'date'),

    currentEncounters: computed('selectedDate', 'encounters.[]', function currentEncounters() {
      var selectedDate = this.get('selectedDate');
      return this.get('encounters').filter(function (encounter) {
        return encounter.get('date').isSame(selectedDate, 'day') && !encounter.get('isDeleted');
      });
    }),

    currentAbsence: computed('selectedDate', 'absences.[]', function currentAbsence() {
      var selectedDate = this.get('selectedDate');
      return this.get('absences').find(function (absence) {
        return absence.get('date').isSame(selectedDate, 'day');
      });
    }),

    toggleAbsentDisabled: computed('currentDayLocked', 'currentEncounters.[]', 'currentAbsence', 'currentAbsence.isSaving', function toggleAbsentDisabled() {
      var encounters = this.get('currentEncounters');
      var absence = this.get('currentAbsence');
      return this.get('currentDayLocked') || encounters.length > 0 || (absence ? absence.get('isSaving') : false);
    }),

    currentEncounter: null,
    currentEncounterOrNew: computed('currentEncounters.[]', 'currentEncounter', 'selectedDate', function () {
      var encounters = this.get('currentEncounters');
      var currentEncounter = this.get('currentEncounter');
      var date = this.get('selectedDate');

      if (!currentEncounter || !currentEncounter.get('date').isSame(date, 'day')) {
        if (currentEncounter) {
          currentEncounter.rollbackAttributes();
        }
        var encounter = encounters.find(function (e) {
          return e.get('date').isSame(date, 'day');
        });
        if (encounter) {
          this.set('currentEncounter', encounter);
        } else if (date) {
          var e = this.get('store').createRecord(this.get('encounterType'));
          e.set('form', this.get('form'));
          e.set('date', date);
          e.set('minutes', this.get('form.suggestedMinutes') || 0);
          this.set('currentEncounter', e);
        }
      }
      return this.get('currentEncounter');
    }),

    canSubmit: computed('currentDayLocked', 'currentEncounter.isComplete', 'currentEncounter.hasDirtyAttributes', 'currentEncounter.isSaving', function () {
      return !this.get('currentDayLocked') && this.get('currentEncounter.isComplete') && this.get('currentEncounter.hasDirtyAttributes') && !this.get('currentEncounter.isSaving');
    }),

    canDelete: computed('currentDayLocked', 'currentEncounter.isNew', 'currentEncounter.isDeleted', 'currentEncounter.isSaving', function () {
      return !this.get('currentDayLocked') && !this.get('currentEncounter.isNew') && !this.get('currentEncounter.isDeleted') && !this.get('currentEncounter.isSaving');
    }),

    actions: {
      calendarSelectDate: function calendarSelectDate(date) {
        this.sendAction('selectDate', date);
      },

      toggleAbsent: function toggleAbsent() {
        var _this2 = this;

        var absences = this.get('absences');
        var absence = this.get('currentAbsence');

        if (absence) {
          absence.destroyRecord().then(function (absence) {
            _this2.get('toast').success('Unmarked student absent');
          }, function (response) {
            var errorMessage = response.message ? response.message : 'Server Error. Please Refresh and try again.';
            _this2.get('toast').error('Failed to unmark student absent: ' + errorMessage);
          });
        } else {
          var _absence = this.get('store').createRecord('absence', {
            date: this.get('selectedDate'),
            student: this.get('student'),
            formType: this.get('formType')
          });
          _absence.save().then(function (absence) {
            absences.pushObject(absence);
            _this2.get('toast').success('Marked student absent');
          }, function (response) {
            var errorMessage = response.message ? response.message : 'Server Error. Please Refresh and try again.';
            _this2.get('toast').error('Failed to mark student absent: ' + errorMessage);
          });
        }
      },

      recordEncounter: function recordEncounter() {
        var _this3 = this;

        var encounters = this.get('encounters');
        var encounter = this.get('currentEncounter');
        encounters.pushObject(encounter);
        encounter.save().then(function (encounter) {
          encounters.pushObject(encounter);
          _this3.send('recordedEncounter', encounter);
          _this3.get('toast').success('Saved encounter');
        }, function (response) {
          var errorMessage = response.message ? response.message : 'Server Error. Please Refresh and try again.';
          _this3.get('toast').error('Failed to save encounter: ' + errorMessage);
        });
      },

      deleteEncounter: function deleteEncounter() {
        var _this4 = this;

        var encounters = this.get('encounters');
        var encounter = this.get('currentEncounter');
        encounter.destroyRecord().then(function (encounter) {
          encounters.removeObject(encounter);
          _this4.set('currentEncounter', null);
          _this4.send('deletedEncounter', encounter);
          _this4.get('toast').success('Deleted encounter');
        }, function (response) {
          var errorMessage = response.message ? response.message : 'Server Error. Please Refresh and try again.';
          _this4.get('toast').error('Failed to delete encounter: ' + errorMessage);
        });
      },

      clearEncounter: function clearEncounter() {
        var encounter = this.get('currentEncounter');
        if (encounter) {
          encounter.rollbackAttributes();
          this.set('currentEncounter', null);
        }
      }
    }
  });
});