define('client/helpers/moment-format-standard', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports.momentFormatStandard = momentFormatStandard;

  function momentFormatStandard(params) {
    var date = params[0];

    return (0, _moment['default'])(date).format('M/D/YYYY');
  }

  exports['default'] = _ember['default'].Helper.helper(momentFormatStandard);
});