define("client/templates/components/form-session", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 6
            },
            "end": {
              "line": 21,
              "column": 6
            }
          },
          "moduleName": "client/templates/components/form-session.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "input-group m-t");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "input-group-btn");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "btn btn-default");
          dom.setAttribute(el3, "type", "button");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "input-group-btn");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "btn btn-default");
          dom.setAttribute(el3, "type", "button");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1, 1]);
          var element2 = dom.childAt(element0, [5, 1]);
          var morphs = new Array(7);
          morphs[0] = dom.createAttrMorph(element1, 'disabled');
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1, 0, 0);
          morphs[3] = dom.createMorphAt(element0, 3, 3);
          morphs[4] = dom.createAttrMorph(element2, 'disabled');
          morphs[5] = dom.createElementMorph(element2);
          morphs[6] = dom.createMorphAt(element2, 0, 0);
          return morphs;
        },
        statements: [["attribute", "disabled", ["subexpr", "if", [["get", "locked", ["loc", [null, [14, 72], [14, 78]]], 0, 0, 0, 0], "disabled", ""], [], ["loc", [null, [null, null], [14, 94]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["decrementPeopleCount"], [], ["loc", [null, [14, 95], [14, 128]]], 0, 0], ["inline", "fa-icon", ["minus"], [], ["loc", [null, [14, 129], [14, 148]]], 0, 0], ["inline", "input", [], ["class", "form-control text-center", "inputmode", "numeric", "value", ["subexpr", "@mut", [["get", "count", ["loc", [null, [16, 79], [16, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "if", [["get", "locked", ["loc", [null, [16, 98], [16, 104]]], 0, 0, 0, 0], "disabled", ""], [], ["loc", [null, [16, 94], [16, 119]]], 0, 0]], ["loc", [null, [16, 12], [16, 121]]], 0, 0], ["attribute", "disabled", ["subexpr", "if", [["get", "locked", ["loc", [null, [18, 72], [18, 78]]], 0, 0, 0, 0], "disabled", ""], [], ["loc", [null, [null, null], [18, 94]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["incrementPeopleCount"], [], ["loc", [null, [18, 95], [18, 128]]], 0, 0], ["inline", "fa-icon", ["plus"], [], ["loc", [null, [18, 129], [18, 147]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 6
          }
        },
        "moduleName": "client/templates/components/form-session.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-xs-6");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" Individual\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-xs-6");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" Group\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [1, 1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element5, [1]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element4, 'class');
        morphs[1] = dom.createAttrMorph(element4, 'disabled');
        morphs[2] = dom.createElementMorph(element4);
        morphs[3] = dom.createMorphAt(element4, 1, 1);
        morphs[4] = dom.createAttrMorph(element6, 'class');
        morphs[5] = dom.createAttrMorph(element6, 'disabled');
        morphs[6] = dom.createElementMorph(element6);
        morphs[7] = dom.createMorphAt(element6, 1, 1);
        morphs[8] = dom.createMorphAt(element5, 3, 3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["btn btn-bubble btn-block ", ["subexpr", "if", [["get", "isIndividualActive", ["loc", [null, [3, 53], [3, 71]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [3, 48], [3, 82]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["subexpr", "if", [["get", "locked", ["loc", [null, [3, 98], [3, 104]]], 0, 0, 0, 0], "disabled", ""], [], ["loc", [null, [null, null], [3, 120]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["selectIndividual"], [], ["loc", [null, [3, 121], [3, 150]]], 0, 0], ["inline", "fa-icon", ["user"], [], ["loc", [null, [4, 10], [4, 28]]], 0, 0], ["attribute", "class", ["concat", ["btn btn-bubble btn-block ", ["subexpr", "if", [["get", "isGroupActive", ["loc", [null, [8, 53], [8, 66]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [8, 48], [8, 77]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["subexpr", "if", [["get", "locked", ["loc", [null, [8, 93], [8, 99]]], 0, 0, 0, 0], "disabled", ""], [], ["loc", [null, [null, null], [8, 115]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["selectGroup"], [], ["loc", [null, [8, 116], [8, 140]]], 0, 0], ["inline", "fa-icon", ["users"], [], ["loc", [null, [9, 10], [9, 29]]], 0, 0], ["block", "if", [["get", "isGroupActive", ["loc", [null, [11, 12], [11, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 6], [21, 13]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});