define('client/components/signature-pad', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    // attributes
    signed: false,

    selector: '.signature-pad-body canvas',

    // properties
    canvas: null, // initialized in didRender
    pad: null, // initialized in didRender
    resizeHandler: null, //initialized in didRender,

    didRender: function didRender() {
      if (!this.get('canvas')) {
        var canvas = $(this.get('selector'))[[0]];
        this.set('canvas', canvas);
        this.set('confirmSignature', _ember['default'].run.bind(this, this.confirmSignature));
        var pad = new SignaturePad(canvas, {
          onEnd: this.get('confirmSignature')
        });
        this.set('pad', pad);

        this.set('resizeHandler', _ember['default'].run.bind(this, this.handleResize));

        jQuery(window).on('resize', this.get('resizeHandler'));

        // boundResize needs to run on initialization to correct for screen dpi
        // however, due to a likely race condition it's execution must be delayed
        setTimeout(this.get('resizeHandler'), 100);
      }
    },

    didDestroyElement: function didDestroyElement() {
      jQuery(window).unbind('resize', this.get('resizeHandler'));
      this.set('resizeHandler', null);
    },

    confirmSignature: function confirmSignature() {
      this.set('signed', true);
    },

    handleResize: function handleResize() {
      var canvas = this.get('canvas');
      var pad = this.get('pad');

      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);

      pad.clear();
      this.set('signed', false);
    },

    actions: {
      clear: function clear() {
        this.get('pad').clear();
        this.set('signed', false);
      }
    }
  });

  // Crop signatures from:
  // https://github.com/szimek/signature_pad/issues/49#issue-29108215
  SignaturePad.prototype.removeBlanks = function () {
    var imgWidth = this._ctx.canvas.width;
    var imgHeight = this._ctx.canvas.height;
    var imageData = this._ctx.getImageData(0, 0, imgWidth, imgHeight),
        data = imageData.data,
        getAlpha = function getAlpha(x, y) {
      return data[(imgWidth * y + x) * 4 + 3];
    },
        scanY = function scanY(fromTop) {
      var offset = fromTop ? 1 : -1;

      // loop through each row
      for (var y = fromTop ? 0 : imgHeight - 1; fromTop ? y < imgHeight : y > -1; y += offset) {

        // loop through each column
        for (var x = 0; x < imgWidth; x++) {
          if (getAlpha(x, y)) {
            return y;
          }
        }
      }
      return null; // all image is white
    },
        scanX = function scanX(fromLeft) {
      var offset = fromLeft ? 1 : -1;

      // loop through each column
      for (var x = fromLeft ? 0 : imgWidth - 1; fromLeft ? x < imgWidth : x > -1; x += offset) {

        // loop through each row
        for (var y = 0; y < imgHeight; y++) {
          if (getAlpha(x, y)) {
            return x;
          }
        }
      }
      return null; // all image is white
    };

    var cropTop = scanY(true),
        cropBottom = scanY(false),
        cropLeft = scanX(true),
        cropRight = scanX(false);

    var relevantData = this._ctx.getImageData(cropLeft, cropTop, cropRight - cropLeft, cropBottom - cropTop);
    this._canvas.width = cropRight - cropLeft;
    this._canvas.height = cropBottom - cropTop;
    this._ctx.clearRect(0, 0, cropRight - cropLeft, cropBottom - cropTop);
    this._ctx.putImageData(relevantData, 0, 0);
  };
});