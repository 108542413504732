define('client/components/form-nursing', ['exports', 'ember', 'client/mixins/therapy-form-mixin'], function (exports, _ember, _clientMixinsTherapyFormMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_clientMixinsTherapyFormMixin['default'], {
    classNames: ['form', 'form-nursing'],
    encounterType: 'nursingEncounter',
    apiPath: '/nursing-encounters',

    canSubmit: computed('currentDayLocked', 'encountersAreComplete', 'encountersWithDirtyAttributes', 'sessionsToDelete.[]', 'newSessions.[]', function () {
      return !this.get('currentDayLocked') && this.get('encountersAreComplete') && (this.get('encountersWithDirtyAttributes').length > 0 || this.get('newSessions').length > 0 || this.get('sessionsToDelete').length > 0) && !this.get('isSaving');
    })

  });
});