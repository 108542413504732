define('client/models/iep', ['exports', 'ember-data', 'ember-data-has-many-query'], function (exports, _emberData, _emberDataHasManyQuery) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend(_emberDataHasManyQuery['default'].ModelMixin, {
    student: belongsTo('student'),

    startDate: attr('moment-day'),
    endDate: attr('moment-day'),

    speechTherapyForm: belongsTo('speech-therapy-form'),
    nursingForm: belongsTo('nursing-form'),
    physicalTherapyForm: belongsTo('physical-therapy-form'),
    occupationalTherapyForm: belongsTo('occupational-therapy-form'),
    mentalHealthForm: belongsTo('mental-health-form'),
    personalCareForm: belongsTo('personal-care-form'),
    specialTransportationForm: belongsTo('special-transportation-form')
  });
});