define('client/mixins/therapy-encounter-model-mixin', ['exports', 'ember-data', 'ember', 'lodash', 'moment'], function (exports, _emberData, _ember, _lodash, _moment) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;
  exports['default'] = Mixin.create({
    date: attr('moment-day'),
    otherStudentsCount: attr('number', { defaultValue: 1 }),
    startTime: attr('moment-time', { defaultValue: null }),
    endTime: attr('moment-time', { defaultValue: null }),
    isTelehealth: attr('boolean', { defaultValue: false }),
    telehealthMode: attr('string', { defaultValue: null }),
    telehealthProviderSite: attr('string', { defaultValue: null }),
    telehealthStudentSite: attr('string', { defaultValue: null }),
    minutes: attr('number', { defaultValue: 0 }),
    notes: attr('string'),

    form: belongsTo('form', { polymorphic: true }),
    objectives: hasMany('objective'),

    hasDirtyObjectiveRelationship: computed('objectives.[]', function hasDirtyObjectiveRelationship() {
      var _this = this;

      var canonical = this.get('objectives.content.canonicalState').mapBy('id');
      var current = this.get('objectives.content.currentState').mapBy('id');
      if (canonical.length !== current.length) {
        return true;
      }
      return !this.get('objectives.content.canonicalState').every(function (item) {
        return _this.get('objectives.content.currentState').mapBy('id').includes(item.id);
      });
    }),

    isComplete: computed('objectives', 'otherStudentsCount', 'startTime', 'endTime', 'isTelehealth', 'telehealthMode', 'telehealthProviderSite', 'telehealthStudentSite', function isComplete() {
      var startTime = this.get('startTime');
      var endTime = this.get('endTime');
      var metrics = [Boolean(+this.get('objectives.length') > 0), Boolean(+this.get('otherStudentsCount') > 0), Boolean(+this.get('startTime') != null), Boolean(+this.get('endTime') != null), Boolean(this.get('isTelehealth') ? this.get('telehealthMode') != null : true), Boolean(this.get('isTelehealth') ? this.get('telehealthProviderSite') != null : true), Boolean(this.get('isTelehealth') ? this.get('telehealthStudentSite') != null : true), Boolean(startTime && endTime ? (0, _moment['default'])(this.get('endTime').format('h:mma'), 'h:mma').isAfter((0, _moment['default'])(this.get('startTime').format('h:mma'), 'h:mma')) : false)];
      window.encounter = this;

      return metrics.length === _lodash['default'].compact(metrics).length;
    })
  });
});