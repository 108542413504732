define('client/transforms/form-fields', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) return null;

      var array = [];
      for (var key in serialized) {
        if (serialized.hasOwnProperty(key)) {
          array.push(_ember['default'].Object.create(_extends({ id: key }, serialized[key])));
        }
      }
      return array;
    },

    serialize: function serialize(deserialized) {
      if (!deserialized) return null;

      var object = deserialized.reduce(function (previous, item) {
        previous[item.id] = item.getProperties('name', 'value', 'type', 'required');
        return previous;
      }, {});
      return object;
    }
  });
});