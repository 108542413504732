define('client/components/objective-list', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    goal: null,
    encounters: null,
    tagName: 'ul',
    classNames: ['objective-list'],

    goalObjectives: computed('goal.id', 'encounters.@each.objectives.[]', function goalObjectives() {
      var goalId = this.get('goal.id');
      return this.get('encounters').reduce(function (objectives, encounter) {
        return objectives.concat(encounter.get('objectives').toArray());
      }, []).filter(function (objective) {
        return objective.get('goal.id') === goalId;
      }).uniq('id');
    })
  });
});