define('client/components/form-personal-care', ['exports', 'ember', 'client/mixins/form-mixin', 'moment'], function (exports, _ember, _clientMixinsFormMixin, _moment) {
  var EmberObject = _ember['default'].Object;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend(_clientMixinsFormMixin['default'], {
    classNames: ['form', 'form-personal-care'],

    ajax: inject.service(),

    encountersLoading: false,

    encounterType: 'personalCareEncounter',
    timeStudies: computed.alias('form.timeStudies'),

    calendarHighlightedDates: computed('form.datesWithEncounters', function () {
      return this.get('form.datesWithEncounters').map(function (d) {
        return (0, _moment['default'])(d);
      });
    }),

    serviceViewModels: computed('timeStudies.[]', 'currentEncounters.[]', function currentServices() {
      var studies = this.get('timeStudies');
      var encounters = this.get('currentEncounters');

      var services = studies.map(function (study) {
        var type = study.get('timeStudyType');
        return EmberObject.create({
          id: type.get('id'),
          name: type.get('name'),
          active: true, // has an active time study
          study: study,
          encounters: [],
          isSelectedForLogging: false,
          isShowingEncounters: false,
          logCount: 1
        });
      });

      // link up the encounters and add non-active services
      encounters.forEach(function (encounter) {
        var type = encounter.get('timeStudyType');
        var service = services.findBy('id', type.get('id'));
        if (!service) {
          service = EmberObject.create({
            id: type.get('id'),
            name: type.get('name'),
            active: false, // has an encounter, but not an active time study
            study: null,
            encounters: [],
            isSelectedForLogging: false,
            isShowingEncounters: false,
            logCount: 1
          });
          services.push(service);
        }
        service.encounters.push(encounter);
      });

      return services.sortBy('name');
    }),

    anyServicesAreSelected: computed('serviceViewModels.@each.isSelectedForLogging', function () {
      return this.get('serviceViewModels').isAny('isSelectedForLogging');
    }),

    actions: {
      toggleService: function toggleService(serviceState) {
        serviceState.toggleProperty('isSelectedForLogging');
      },

      incrementEncounterCount: function incrementEncounterCount(serviceState) {
        serviceState.incrementProperty('logCount');
      },

      decrementEncounterCount: function decrementEncounterCount(serviceState) {
        if (serviceState.logCount > 1) {
          serviceState.decrementProperty('logCount');
        }
      },

      toggleServiceBadge: function toggleServiceBadge(serviceState) {
        serviceState.toggleProperty('isShowingEncounters');
      },

      recordEncounters: function recordEncounters() {
        var _this = this;

        var data = {
          notes: this.get('notes'),
          formId: this.get('form.id'),
          date: this.get('selectedDate').format('YYYY-MM-DD'),
          encounters: this.get('serviceViewModels').filter(function (ss) {
            return ss.isSelectedForLogging && ss.logCount > 0;
          }).map(function (ss) {
            return {
              count: ss.logCount,
              timeStudyTypeId: ss.get('study.timeStudyType.id'),
              minutes: ss.get('study.minutes') || 0
            };
          })
        };

        this.get('ajax').post('/personal-care-encounters/bulk', {
          data: JSON.stringify({ data: data })
        }).then(function (response) {
          _this.get('store').pushPayload(response);
          var newIds = response.data.mapBy('id');
          var newEncounters = _this.get('store').peekAll('personalCareEncounter').filter(function (e) {
            return newIds.includes(e.get('id'));
          });
          _this.get('form.encounters').pushObjects(newEncounters);
          _this.get('toast').success('Saved encounters');
        }, function (response) {
          var errorMessage = response.message ? response.message : 'Server Error. Please Refresh and try again.';
          _this.get('toast').error('Failed to save encounter: ' + errorMessage);
        });
      },

      deleteEncounter: function deleteEncounter(encounter) {
        var _this2 = this;

        encounter.destroyRecord().then(function () {
          _this2.get('toast').success('Deleted encounter');
        }, function (response) {
          var errorMessage = response.message ? response.message : 'Server Error. Please Refresh and try again.';
          _this2.get('toast').error('Failed to delete encounter: ' + errorMessage);
        });
      }
    }
  });
});