define("client/helpers/math", ["exports", "ember"], function (exports, _ember) {
  exports.math = math;

  function math(params) {
    var lvalue = parseFloat(params[0]);
    var operator = params[1];
    var rvalue = parseFloat(params[2]);
    return ({
      "+": lvalue + rvalue,
      "-": lvalue - rvalue,
      "*": lvalue * rvalue,
      "/": lvalue / rvalue,
      "%": lvalue % rvalue
    })[operator];
  }

  exports["default"] = _ember["default"].Helper.helper(math);
});