define('client/mixins/form-route-date-mixin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    model: function model(_ref) {
      var formId = _ref.formId;
      var _ref$date = _ref.date;
      var date = _ref$date === undefined ? moment().format('YYYY-MM-DD') : _ref$date;

      return this.get('store').findRecord(this.get('formSlug'), formId, {
        reload: true,
        include: ['restricted-dates', 'encounters' + (date ? ':for_date(' + date + ')' : ''), 'absences', 'student', 'submissions', 'form-type'].join(',')
      });
    },

    actions: {
      selectDate: function selectDate(date) {
        var _this = this;

        date = date.format('YYYY-MM-DD');
        this.replaceWith({ queryParams: { date: date } });

        var toggleLoading = function toggleLoading(loading) {
          return _this.set('controller.encountersLoading', loading);
        };

        toggleLoading(true);
        this.get('store').findRecord(this.get('formSlug'), this.get('currentModel.id'), {
          reload: true,
          include: 'encounters:for_date(' + date + ')'
        })['finally'](function () {
          return toggleLoading(false);
        });
      }
    }
  });
});