define('client/components/form-list', ['exports', 'ember', 'lodash'], function (exports, _ember, _lodash) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['form-item'],

    submissions: null,
    formGroups: computed('submissions.[]', function () {
      var submissions = this.get('submissions');

      return this.get('submissions').mapBy('form.slug').uniq().map(function (slug) {
        var title = submissions.findBy('form.slug', slug).get('form.title');
        var filteredSubmissions = submissions.filter(function (s) {
          return s.get('form.slug') === slug;
        });

        return _ember['default'].Object.create({
          title: title,
          slug: slug,
          submissions: _lodash['default'].chain(filteredSubmissions).sortBy(function (submission) {
            return submission.get('form.endDate').format('YYYYMMDD') + submission.get('startDate').format('YYYYMMDD');
          }).reverse().value()

        });
      });
    })
  });
});