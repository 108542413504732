define('client/services/current-user', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Service.extend({
    session: service('session'),
    store: service(),

    user: null,

    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        return this.get('store').find('user', 'me').then(function (user) {
          _this.set('user', user);
        });
      }
    }
  });
});