define('client/routes/frame/students/student', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    model: function model(params) {
      return this.store.findRecord('student', +params.studentId, { backgroundReload: true });
    },

    serialize: function serialize(model) {
      return { studentId: model.get('id') };
    },

    actions: {
      willTransition: function willTransition(transition) {
        if (this.currentModel && transition.targetName === 'frame.students.student.index') {
          this.refresh();
        }
        return true;
      }
    }

  });
});