define('client/routes/frame', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    beforeModel: function beforeModel(transition) {
      // Redirect to the homepage, currently the students route
      if (transition.targetName === 'frame.index' || transition.targetName === 'frame') {
        this.transitionTo('frame.students');
      }
    }
  });
});