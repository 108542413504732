define('client/mixins/form-route-mixin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    formSlug: null,
    form: null,

    model: function model(_ref) {
      var formId = _ref.formId;
      var _ref$date = _ref.date;
      var date = _ref$date === undefined ? moment().format('YYYY-MM-DD') : _ref$date;

      return this.get('store').findRecord(this.get('formSlug'), formId, { reload: true });
    },

    setupController: function setupController(controller, model) {
      controller.set('form', model);
      controller.set('student', this.modelFor('frame.students.student'));
    },

    setPageTitle: null,
    afterModel: function afterModel() {
      var title = this.get('titleService');
      title.setProperties({
        current: this.get('pageTitle') + ' -- ' + this.modelFor('frame.students.student').get('fullName'),
        showBack: true
      });
    },

    actions: {
      selectDate: function selectDate(date) {
        date = date.format('YYYY-MM-DD');
        this.replaceWith({ queryParams: { date: date } });
      }
    }
  });
});