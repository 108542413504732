define('client/transforms/moment-time', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized ? (0, _moment['default'])(serialized, 'hh:mm:ss') : null;
    },

    serialize: function serialize(deserialized) {
      return deserialized ? deserialized.format('HH:mm') : null;
    }
  });
});