define('client/components/form-time-entry', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    // Attributes
    minutes: null,
    forceActive: false,
    locked: false,

    // Properties
    isTimeActive: computed('locked', 'forceActive', 'minutes', function () {
      return !this.get('locked') && (this.get('forceActive') || this.get('minutes') > 0);
    }),

    enterTimeButtonLabel: computed('minutes', function enterTimeButtonLabel() {
      var time = +this.get('minutes');
      if (time && time !== 0) {
        var duration = _moment['default'].duration(+time, 'minutes');
        var h = duration.hours();
        var m = duration.minutes();
        var hours = h ? h + ' H' : '';
        var mins = m ? m + ' MIN' : '';
        return hours + ' ' + mins;
      }

      return 'Enter Time';
    }),

    actions: {
      toggleTimeActive: function toggleTimeActive() {
        if (!this.get('locked') && !this.get('forceActive') && this.get('isTimeActive')) {
          this.set('minutes', 0);
        }
      },

      decrementTime: function decrementTime() {
        var total = this.get('minutes') - 1;
        if (total > 0) {
          this.set('minutes', total);
        } else {
          this.set('minutes', 0);
        }
      },

      incrementTime: function incrementTime() {
        var max = this.get('timeMax');
        var time = Math.round(+this.get('minutes') + 1);

        if (time > max) {
          this.set('minutes', max);
        } else {
          this.set('minutes', time);
        }
      }
    }
  });
});