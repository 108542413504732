define('client/services/ajax', ['exports', 'client/config/environment', 'ember', 'ember-ajax/services/ajax'], function (exports, _clientConfigEnvironment, _ember, _emberAjaxServicesAjax) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _emberAjaxServicesAjax['default'].extend({
    session: inject.service(),

    contentType: 'application/json',
    host: _clientConfigEnvironment['default'].apiRoot,
    namespace: '/api/v1',

    headers: computed('session', function () {
      var headers = {};
      var token = this.get('session.data.authenticated.access_token');
      if (token) {
        headers.Authorization = 'Bearer ' + token;
      }
      return headers;
    })
  });
});