define('client/transitions', ['exports'], function (exports) {
  exports['default'] = function () {
    this.transition(this.fromRoute('frame.students.index'), this.toRoute('frame.students.student'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('frame.students'), this.toRoute('frame.forms'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.childOf('.form-list-item section'), this.use('explode', {
      matchBy: 'data-submission-id',
      use: ['fly-to', { duration: 1000, easing: 'linear' }]
    }));
  };

  ;
});