define('client/components/submission-form', ['exports', 'ember', 'lodash', 'moment'], function (exports, _ember, _lodash, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = Component.extend({

    submission: null,
    form: computed.alias('submission.form'),
    student: computed.alias('submission.student'),
    pcaEncounters: computed.alias('submission.encounterSummary'),
    currentDate: Date.now(),
    signed: false,
    pad: null,

    toast: inject.service(),
    currentUser: inject.service('current-user'),
    routing: inject.service('-routing'),
    useOldObjectiveList: ['special-transportation', 'personal-care'],

    canSubmit: computed('submission.isSaving', 'submission.fields.@each.value', 'signed', 'hasNotes', function () {
      var fields = this.get('submission.fields');
      var hasNotes = this.get('hasNotes');
      var invalid = fields.any(function (field) {
        if (field.get('required')) {
          var fieldEmpty = isEmpty(field.get('value'));
          return field.get('name') === 'Notes' ? !hasNotes && fieldEmpty : fieldEmpty;
        }
        return false;
      });
      return !invalid && this.get('signed') && !this.get('submission.isSaving');
    }),

    filteredEncounters: computed('form.encounters', 'form.availableGoals', 'filteredAbsences.[]', function () {
      var _this = this;

      var allEncounters = this.get('form.encounters');
      return allEncounters ? allEncounters.filter(function (encounter) {
        return encounter.get('date').isSameOrBefore(_this.get('submission.endDate')) && encounter.get('date').isSameOrAfter(_this.get('submission.startDate'));
      }).sort(function (first, second) {
        return first.get('date') - second.get('date');
      }) : null;
    }),

    filteredAbsences: computed('form.absences', function () {
      var _this2 = this;

      var allAbsences = this.get('form.absences');
      return allAbsences ? allAbsences.filter(function (absence) {
        return absence.get('date').isSameOrBefore(_this2.get('submission.endDate')) && absence.get('date').isSameOrAfter(_this2.get('submission.startDate'));
      }).sort(function (first, second) {
        return first.get('date') - second.get('date');
      }) : null;
    }),

    filteredEncountersWithAbsences: computed('filteredEncounters.[]', 'filteredAbsences.[]', function () {
      var filteredEncounters = this.get('filteredEncounters');
      var filteredAbsences = this.get('filteredAbsences') || [];
      return filteredEncounters.concat(filteredAbsences).sort(function (first, second) {
        return first.get('date') - second.get('date');
      });
    }),

    filteredSessions: computed('filteredEncountersWithAbsences.[]', function () {
      var filteredEncountersWithAbsences = this.get('filteredEncountersWithAbsences');

      return filteredEncountersWithAbsences ? _lodash['default'].chain(filteredEncountersWithAbsences).groupBy(function (encounter) {
        return (0, _moment['default'])(encounter.get('date')).format('D');
      }).map(function (value, key) {
        var absence = value && value.length > 0 ? value[0].get('constructor.modelName') === 'absence' : false;
        return {
          day: key,
          sessions: !absence ? value : null,
          dayMinutes: !absence ? _lodash['default'].reduce(value, function (sum, session) {
            return sum + (session.get('minutes') ? session.get('minutes') : 0);
          }, 0) : null,
          notes: value && value.length > 0 && !absence ? value[0].get('notes') : null
        };
      }).value() : null;
    }),

    filteredGoals: computed('form.availableGoals', 'filteredEncounters.[]', function () {
      var filteredEncounters = this.get('filteredEncounters');
      var availableGoals = this.get('form.availableGoals').map(function (goal) {
        return goal.get('id');
      });
      var filteredGoalIds = filteredEncounters.reduce(function (objectives, encounter) {
        return objectives.concat(encounter.get('objectives').toArray());
      }, []).filter(function (objective) {
        return availableGoals.includes(objective.get('goal.id'));
      }).uniq('id').map(function (objective) {
        return objective.get('goal.id');
      });
      return this.get('form.availableGoals').filter(function (goal) {
        return filteredGoalIds.includes(goal.get('id'));
      });
    }),

    hasNotes: computed('filteredSessions', function () {
      var sessions = this.get('filteredSessions');
      return sessions.any(function (session) {
        return session.notes !== null;
      });
    }),

    actions: {
      submit: function submit() {
        var _this3 = this;

        var submission = this.get('submission');
        var pad = this.get('pad');

        pad.removeBlanks();
        this.set('submission.signature', pad.toDataURL());

        submission.save().then(function () {
          _this3.get('toast').success('Submission completed');
          history.back();
        })['catch'](function () {
          _this3.get('toast').error('Submission failed. Check that form is signed');
        });
      }
    }

  });
});