define('client/models/physical-therapy-form', ['exports', 'ember-data', 'client/models/form'], function (exports, _emberData, _clientModelsForm) {
  var hasMany = _emberData['default'].hasMany;
  var attr = _emberData['default'].attr;
  exports['default'] = _clientModelsForm['default'].extend({
    title: 'Physical Therapy',
    slug: 'physical-therapy',
    suggestedMinutes: attr('number', { defaultValue: 0 }),
    availableGoals: hasMany('goal'),
    encounters: hasMany('physical-therapy-encounter')
  });
});