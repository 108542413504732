define('client/initializers/rollbar', ['exports', 'client/config/environment'], function (exports, _clientConfigEnvironment) {
  exports.initialize = initialize;

  function initialize() /* application */{
    if (_clientConfigEnvironment['default'].rollbar.accessToken) {
      // TODO: Rollbar isn't on window for some reason
      // window.Rollbar.init(config.rollbar, window);
    }
  }

  exports['default'] = {
    name: 'rollbar',
    after: 'env-overrides',
    initialize: initialize
  };
});