define('client/routes/frame/students/student/forms/personal-care', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'client/mixins/titled-page', 'client/mixins/form-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _clientMixinsTitledPage, _clientMixinsFormRouteMixin) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _clientMixinsTitledPage['default'], _clientMixinsFormRouteMixin['default'], {
    formSlug: 'personalCareForm',
    pageTitle: 'Care',
    showBack: true,

    model: function model(_ref) {
      var formId = _ref.formId;
      var _ref$date = _ref.date;
      var date = _ref$date === undefined ? moment().format('YYYY-MM-DD') : _ref$date;

      return this.get('store').findRecord(this.get('formSlug'), formId, {
        reload: true,
        include: ['time-studies', 'restricted-dates', 'encounters' + (date ? ':for_date(' + date + ')' : ''), 'absences', 'student', 'submissions', 'form-type'].join(',')
      });
    },

    actions: {
      selectDate: function selectDate(date) {
        var _this = this;

        date = date.format('YYYY-MM-DD');
        this.replaceWith({ queryParams: { date: date } });

        var toggleLoading = function toggleLoading(loading) {
          return _this.set('controller.encountersLoading', loading);
        };

        toggleLoading(true);
        this.get('store').findRecord(this.get('formSlug'), this.get('currentModel.id'), {
          reload: true,
          include: 'encounters:for_date(' + date + ')'
        })['finally'](function () {
          return toggleLoading(false);
        });
      }
    }
  });
});