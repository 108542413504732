define('client/components/dynamic-field', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    field: null,

    actions: {
      setValue: function setValue(value) {
        var field = this.get('field');
        field.set('value', value);
      }
    }

  });
});