define('client/routes/frame/students/student/forms/index', ['exports', 'ember', 'client/mixins/titled-page'], function (exports, _ember, _clientMixinsTitledPage) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_clientMixinsTitledPage['default'], {
    pageTitle: 'Student Forms',
    showBack: true,

    model: function model() {
      var student = this.modelFor('frame.students.student');
      return this.get('store').query('submission', { filter: 'student_id=' + student.get('id') });
    },

    setupController: function setupController(controller, model) {
      controller.set('submissions', model);
      controller.set('student', this.modelFor('frame.students.student'));
    }
  });
});